'use strict'

import * as conf from "./config.js";
import {slider, setgetState as slider_setgetState } from "./slider.js";
import { noSubsSupport, setgetState as subs_setgetState } from "./subs.js";
import { showAlert, showAlertHistory, setgetState as alert_setgetState, updateHistory } from "./alert.js";
import { requestJSON } from "./utils.js";

let state = {}
state = loadState();

window.addEventListener('load', function() {
  checkSessionExp(state);
});

// tp can be "ok", "warn", "error"
export const setMsgGeneral = (msg, tp) => {
  let eMsgZone = document.querySelector("#general-msg-zone");
  eMsgZone.textContent = msg;
  eMsgZone.classList.remove("warn-zone");
  eMsgZone.classList.remove("ok-zone");

  if (msg == "") {
    eMsgZone.classList.add("hidden");
  } else {
    eMsgZone.classList.remove("hidden");
    if (tp == "warn") {
      eMsgZone.classList.add("warn-zone");
    } else if (tp == "ok") {
      eMsgZone.classList.add("ok-zone")
    }

    setTimeout(() => {
      setMsgGeneral("");
    }, conf.msgTimeout);
  }
};

function saveState(state) {
  localStorage.setItem("state", JSON.stringify(state));
}

function checkSessionExp(st) {
  let checkState = {};
  if (st) {
    checkState = st;
  } else {
    checkState = state; 
  }
  // console.log("checking session exp");
  let exp = checkState?.user_data?.exp;
  if (!exp) {
    return;
  }
  // console.log(`expiring in ${(exp - Date.now())/1000} seconds`);
  if (exp <= Date.now()) {
    logout();
    // saveState(undefined);
  } else {
    checkState.user_data.exp = Date.now() + conf.sesExp;
    saveState(checkState);
  }
}

function loadState() {
  let state = {}

  state = {
    notifSupport: false,
    // user_data: {},
    gidx: 0
  }

  let loaded = localStorage.getItem("state")
  if (loaded) {
    try {
      state = JSON.parse(loaded);
    } catch {
      loaded = false;
    }
  } 
  
  if (loaded == false) {
    saveState(state);
  }

  if (state?.user_data?.exp) {
    checkSessionExp(state);
  }

  return state;
}

function getState() {
  return state;
}

function setState(s) {
  state = {
    ...state, // original state
    ...s      // override with new state
  };
  saveState(state);
}

subs_setgetState(setState, getState);
alert_setgetState(setState, getState);
slider_setgetState(setState, getState);

// -----------------

function getJWTData(jwt) {
  if (jwt == undefined) {
    return undefined;
  }
  var base64Url = jwt.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

// setControls will show/hide elements based on user's access level
function setControls(level) {
  setMsgGeneral("");
  let loginBtn = document.querySelector("nav .buttonS");
  if (!state.user_data || !level) {
    level = 0;
  }
  let group = 0;
  try {
    group = state.user_data.groups[state.user_data.active_group]
    document.querySelector("#trigAlGName").textContent = group.name;
    level = group.level;
  } catch {
    // console.error("no groups data found in user_data");
    state.user_data = undefined;
    saveState();
  }

  // console.log("level:", level);
  if (level >= 1) {
    // show level 1 elements
    document.querySelector("#navTrigAlert").style.display = "";
    document.querySelector("#login").classList.add("hidden");
    document.querySelector("#alertUpdate").classList.remove("hidden");
    loginBtn.classList.remove("btn-green");
    loginBtn.classList.add("btn-red");
    loginBtn.querySelector("span").innerHTML= "&nbsp;Salir";
    loginBtn.querySelector("a").href = "?p=logout";
  } else {
    // hide level 1 elements
    document.querySelector("#navTrigAlert").style.display = "none";
    document.querySelector("#alertUpdate").classList.add("hidden");
    loginBtn.classList.add("btn-green");
    loginBtn.classList.remove("btn-red");
    loginBtn.querySelector("span").innerHTML= "&nbsp;Acceso";
  }

  if (level >= 2) {
    // show level 2 elements
    let items = document.querySelectorAll(".level_2");
    items.forEach(i => {
      i.classList.remove("hidden");
    })
  } else {
    // hide level 2 elements
    let items = document.querySelectorAll(".level_2");
    items.forEach(i => {
      i.classList.add("hidden");
    })
  }
}

async function LogInUser(provider, data) {
  let resp;
  let auth;
  if (provider === "google") {
    auth = `Bearer ${data.jwt}`;
  } else if (provider === "basic") {
    let creds = btoa(`${data.email}:${data.pass}`);
    auth = `Basic ${creds}`;
  } else {
    setMsgGeneral("Error de comunicación [2]", "warn");
    return;
  }

  try {
    resp = await requestJSON(conf.API_BASE_URL + `/v1/login/` + provider, {
      method: "POST",
      headers: {
        "Authorization": auth
      }
    })
  } catch {
    setMsgGeneral("Error de comunicación", "warn");
    return;
  }
  //           ===== THIS IS FOR DEBUG =====
  //                 ===== START =====
  // let resp = JSON.parse(`{
  //   "sid": "e8ed44acf4913fe2f3051f64372d3f2ec61b7a21",
  //   "groups": [
  //       {
  //           "gid": 33,
  //           "name": "Condominio Alicahue 2",
  //           "level": 2
  //       },
  //       {
  //           "gid": 3100,
  //           "name": "Espacio Macul",
  //           "level": 1
  //       }
  //   ]
  // }`);
  //                 =====  END  =====
  if (resp.error) {
    console.error(resp.error);
    setControls(0);
    setMsgGeneral("Usuario NO Autorizado", "warn");
    return;
  }
  
  if (resp.sid.length == 0) {
    // not authorized
    // console.log("unauthorized user");
    setMsgGeneral("Usuario NO Autorizado", "warn");
  } else {
    // console.log(resp);
    // console.log("authorized user")
    resp.active_group = 0;
    resp.exp = Date.now() + conf.sesExp;
    state.user_data = resp;
    saveState(state);
    // console.log(state);
  }

  setControls();
  // let data = getJWTData(jwt);
  // console.log(data);
  urlRoutes(conf.API_BASE_URL + "/?p=trigAlert")
  return
}

// This is the callback for google login.
// From `<div id="g_id_onload" ... data-callback="googleHandleToken" ...</div>`
// in "index.html" file.
window.googleHandleToken = data => {
  LogInUser("google", {"jwt": data.credential});
}

function googleIdInit() {
  google.accounts.id.initialize({
    client_id: conf.gAppClientID
  });
  setControls(0);
}

googleIdInit();

if ('Notification' in window && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register(
    new URL('../sw.js', import.meta.url),
    {type: 'module'}
    );
    state.notifSupport = true;
  } else {
    state.notifSupport = false;
    noSubsSupport(); // To show a "no support" msg in subscription view
}
  
const resetSlider = slider(
  document.querySelector("#alarm"),        // Container
  document.querySelector("#alarmSlider"),  // dragItem
  { text: "ALARMA !" }                     // params
);


// slider(
//   document.querySelector("#fireAlarm"),
//   document.querySelector("#fireAlarmSlider"),
//   { text: "INCENDIO !" }
// );

document.addEventListener("click", e => {
  let li = e.target.closest("li"); 
  if (!li?.matches("nav li") || li?.matches(".toggle")) {
    return;
  }
  if (li.querySelector("a").href.split("/")[3] == "docs") return;

  e.preventDefault();

  let items = document.querySelectorAll("nav .item");
  items.forEach(i => {
    i.classList.remove("active");
  })

  urlRoutes(li.querySelector("a").href);
})

document.addEventListener("DOMContentLoaded", function(event) {
  urlRoutes(document.location.href);
});

const urlRoutes = url => {
  // console.log("url", url);

  if (url) {
    // This is to change the url in the browser
    let pathname = new URL(url).pathname;
    let searchstr = new URL(url).search;
    window.history.replaceState("", "", pathname + searchstr);
  } else {
    setPage();
    return;
  }

  let page = "login"; // default page if none specified
  let params;
  try {
    const q = url.split("?")[1]
    if (q) {
      params = new URLSearchParams(q);
      page = params.get('p');
    }
  
    // console.log(page);
    setPage(page, params);
  } catch (err) {
    console.log(err);
    setPage();
  }
}

const setPage = (page, params) => {
  setControls();
  const pages = document.querySelectorAll("main section");
  pages.forEach(p => {
    p.classList.add("hidden");
  });

  let id;
  let logged_in = false;
  if (state.user_data && state.user_data.groups[state.user_data.active_group].level > 0) {
    id = "#triggerAlert"; // default if logged in user
    logged_in = true;
  } else {
    id = "#login"; // default if unknown page
  }
  let hash;
  switch (page) {
    case "pwdchg":
      id = "#pwdchg";
      break;
    case "subs":
      id = "#subscriptions";
      if (params) {
        let code = params.get("code");
        if (code && code.length > 0) {
          code = code.slice(0,4) + " " + code.slice(4,8);
          document.querySelector("#group_code").value = code;
        } else {
          document.querySelector("#group_code").value = "";
        }
      }
      break;
    case "trigAlert":
      if (logged_in) {
        id = "#triggerAlert";
      } else {
        id = "#alarms";
        document.querySelector("#alert").classList.add("hidden");
        showAlertHistory();
      }
      break;
    case "showAlert":
      id = "#alarms";
      if (params) hash = params.get("h");
      // console.log("alert hash:", hash);
      if (hash) {
        // console.log(`showing alert for hash ${hash}`);
        showAlert(hash);
      } else {
        document.querySelector("#alert").classList.add("hidden");
        showAlertHistory();
      }
      break;
    case "logout":
      logout();
      // console.log("user logout");
      break;
  }
  document.querySelector(id).classList.remove("hidden");
}

export async function logout() {
  if (typeof state.user_data === 'undefined') return;

  if (state.user_data?.sid) {
    // console.log("logging out...")
    try {
      await requestJSON(conf.API_BASE_URL + `/v1/logout`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${state.user_data.sid}`
        }
      });
    } catch (e) {
console.log("unable to connect");
    } finally {
      state.user_data = undefined;
      saveState(state);
      setControls(0);
      urlRoutes();
    }
  }
}

let toggleBtn = document.querySelector(".toggle");
toggleBtn.addEventListener("click", () => {
  let items = document.querySelectorAll("nav .item");
  items.forEach(i => {
    i.classList.toggle("active");
  })
})

let stopBtnActive = true;
const stopBtn = document.querySelector("#stopAlarm");
stopBtn.addEventListener("click", async () => {
  if (!stopBtnActive) {
    return;
  }
  stopBtn.classList.remove("WhiteOnColor2L");
  stopBtn.classList.add("LGrayOnGray");
  stopBtnActive = false;
  setTimeout(() => {
    stopBtn.classList.add("WhiteOnColor2L");
    stopBtn.classList.remove("LGrayOnGray");
    stopBtnActive = true;
  }, 3000);
  // console.log("alarm stop requested");
  let user_data = state.user_data;
  let gid = user_data.groups[user_data.active_group].gid;
  let resp;
  try {
    resp = await requestJSON(conf.API_BASE_URL + `/v1/alerts/cmd`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${user_data.sid}`
      },
      body: JSON.stringify({
        "cmd": "stop",
        "gid": gid
      })
    })
  } catch {
    setMsgGeneral("Error de comunicación", "warn");
  }
  // console.log("gid:", gid);
  // console.log("sid:", user_data.sid);
  // console.log(resp);
  if (!resp) {
    setMsgGeneral("Error de conexión", "warn");
  } else if (resp.error) {
    if (resp.error.status.slice(0,3) == "401") { // Unauthorized
      logout();
    } else {
      setMsgGeneral("Error de conexión", "warn");
    }
  } else {
    setTimeout(() => {
      resetSlider();
    }, 1000);
  }
})

document.querySelector('#login_btn').addEventListener('click', async function() {
  const email = document.querySelector("#login_email").value;
  const pass = document.querySelector("#login_pass").value;

  LogInUser("basic", {"email": email, "pass": pass});
})

document.querySelector('#pwdchg_btn').addEventListener('click', async function() {
  const email = document.querySelector("#pwdchg_email").value;
  const curpass = document.querySelector("#pwdchg_curpass").value;
  const newpass1 = document.querySelector("#pwdchg_newpass1").value;
  const newpass2 = document.querySelector("#pwdchg_newpass2").value;

  if (email == "" || curpass == "" || newpass1 == "" || newpass2 == "") {
    setMsgGeneral("Debe llenar todos los campos", "warn");
    return;
  }

  if (newpass1 != newpass2) {
    setMsgGeneral("Repita clave nueva exactamente", "warn");
    return;
  }
  setMsgGeneral("");

  let resp;
  try {
    let creds = btoa(`${email}:${curpass}`);
    resp = await requestJSON(conf.API_BASE_URL + `/v1/chgpwd`, {
      method: "POST",
      headers: {
        "Authorization": `Basic ${creds}`,
      },
      body: JSON.stringify({
        "newpass": newpass1
      })
    })
  } catch {
    setMsgGeneral("Error de comunicación", "warn");
    return;
  }
  // console.log("gid:", gid);
  // console.log("sid:", user_data.sid);
  // console.log(resp);
  if (!resp || resp.error) {
    setMsgGeneral("Solicitud Rechazada", "warn");
  } else {
    setPage("login");
    setMsgGeneral("Clave Cambiada", "ok");
  }
})

document.querySelector('#pwdchg_cancel_btn').addEventListener('click', async function() {
  setPage();
})

document.querySelector('#alertUpdate').addEventListener('click', async function() {
  // TODO: En esta función deberíamos forzar que se muestre la primera alerta
  // si ocurrió hace menos de 5 minutos
  let response = await updateHistory();
  if (response == 200) {
    showAlertHistory();
  } else if (response == 401) { // unauthorized
    await logout();
  }
})

const combo = document.querySelector("#alertKind");
if (combo) {
  conf.alKnd.map(e => {
    let opt = document.createElement('option');
    opt.value = e.id;
    opt.textContent = e.txt;
    combo.appendChild(opt);
  })
}

// Ensure "Login with masoi" account is active
document.querySelector('#btnNotGlogin').addEventListener('click', async function() {
  document.querySelector("#notGlogin").classList.remove("hidden");
  document.querySelector("#Glogin").classList.add("hidden");
})

// Ensure "Login with google" account is active
document.querySelector('#btnGlogin').addEventListener('click', async function() {
  document.querySelector("#Glogin").classList.remove("hidden");
  document.querySelector("#notGlogin").classList.add("hidden");
})

// FOR DEBUG ONLY
// LogInUser("");
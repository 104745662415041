'use strict'
import * as conf from "./config.js";
import { requestJSON } from "./utils.js";
import { setMsgGeneral, logout } from "./index.js";

// these are to set and get global state
let setState = () => { console.error("not defined yet")};
let getState = () => { console.error("not defined yet")};
export function setgetState(setST, getST) {
  setState = setST;
  getState = getST;
}

export function slider(container, dragItem, params) {
  let active = false;
  let currentX;
  let currentY;
  let initialX;
  let initialY;
  let xOffset = 0;
  let yOffset = 0;

  // This is to prevent the slider from reaching the end of the box
  // if kept at its current position when window is resized.
  window.addEventListener("resize", e => {
    currentX = 0;
    xOffset = 0;
    setTranslate(currentX, currentY, dragItem);
  });

  container.addEventListener("touchstart", dragStart, false);
  container.addEventListener("touchend", dragEnd, false);
  container.addEventListener("touchmove", drag, false);

  container.addEventListener("mousedown", dragStart, false);
  container.addEventListener("mouseup", dragEnd, false);
  container.addEventListener("mousemove", drag, false);

  function dragStart(e) {
    if (e.type === "touchstart") {
      initialX = e.touches[0].clientX - xOffset;
      initialY = e.touches[0].clientY - yOffset;
    } else {
      initialX = e.clientX - xOffset;
      initialY = e.clientY - yOffset;
    }

    if (e.target === dragItem) {
      active = true;
    }
  }

  function dragEnd(e) {
    initialX = currentX;
    initialY = currentY;
    active = false;
  }

  async function drag(e) {
    if (active) {
      e.preventDefault();

      let x, y;
      if (e.type === "touchmove") {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      } else {
        x = e.clientX
        y = e.clientY
      }
      if (x <= initialX) return;

      currentX = x - initialX;
      currentY = y - initialY;

      if (this.offsetWidth < currentX + dragItem.offsetWidth) {
        currentX = this.offsetWidth - dragItem.offsetWidth;
        if (params) {
          params.container = container;
          params.dragItem = dragItem;

          // reached the end. Trigger action
          
          let rc = await sendAlert(this);
          if (rc >= 400) { // error
            currentX = 0;
            this.classList.remove("armed");
            this.querySelector(".sliderText").classList.add("hidden");
            // this.querySelector(".slider").classList.remove("hidden");
            
            setTimeout(() => {
              resetSlider();
            }, 3000);
            if (rc == 401) {
              logout();
            }
            return;
          } else {
            // slider will be available again in 10sec
            setTimeout(() => {
              resetSlider();
            }, 10000);
          }
          dragItem.classList.add("hidden");
        }
      }

      xOffset = currentX;
      // yOffset = currentY;
      yOffset = currentY = 0;

      if (currentX < 0) {
        currentX = xOffset = 0;
      }
      setTranslate(currentX, currentY, dragItem);
    }
  }

  function resetSlider() {
    dragItem.classList.remove("hidden");
    container.classList.remove("armed");
    container.querySelector(".sliderText").classList.add("hidden");

    currentX = 0;
    xOffset = currentX;
    yOffset = currentY = 0;
    setTranslate(currentX, currentY, dragItem);
    alertSent = false;
  }

  return resetSlider.bind(this);
}

function setTranslate(xPos, yPos, el) {
  el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
}

let alertSent = false;
async function sendAlert(sliderBox) {
  // This is to prevent multiple alerts.
  // If the user wants to send another one, he/she needs to wait
  // or refresh the page
  if (alertSent) return 200;
  alertSent = true;

  sliderBox.classList.add("armed");
  // sliderBox.querySelector(".sliderText").textContent = params.text;
  sliderBox.querySelector(".sliderText").classList.remove("hidden");
  sliderBox.querySelector(".slider").classList.add("hidden");
  
  // future:Activation function should be passed as parameter instead of live 
  //      in the slider itself.
  // console.log(params);
  let user_data = getState().user_data
  let gid = user_data.groups[user_data.active_group].gid
  // console.log("gid", gid)

  let knd = Number(document.querySelector("#alertKind").value);
  let init = {
    method: "PUT",
    headers: {
      "Authorization": `Bearer ${user_data.sid}`
    },
    body : JSON.stringify({
      "knd": knd,
      "gid": gid
    })
  };
  // console.log(init);
  let resp;
  let error = 0;
  try {
    resp = await requestJSON(conf.API_BASE_URL + `/v1/alerts`, init)
  } catch {
    error = 400;
  }
  
  if (resp && resp.error) {
    if (resp.error.status.slice(0,3) == '401') {
      error = 401;
    } else {
      error = 400;
    }
  }

  if (error > 0) {
    setMsgGeneral("Error de comunicación", "warn");
    alertSent = false;
    return error;
  }
  return 200;
}

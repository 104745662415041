'use strict';

import { requestJSON, convDate } from "./utils.js";
import * as conf from "./config.js";

// these are to set and get global state
let setState = () => { console.error("not defined yet")};
let getState = () => { console.error("not defined yet")};
export function setgetState(setST, getST) {
  setState = setST;
  getState = getST;
}

const eDate = document.querySelector('#date');
const eType = document.querySelector('#type');
const eUname = document.querySelector('#uname');
const eGeoref = document.querySelector('#georef');
const eNoteSubs = document.querySelector('#notesubs');

const eMsgZone = document.querySelector('#alarms-msg-zone');
const eReport = document.querySelector('#report');

const setMsgZone = text => {
  eMsgZone.textContent = text;

  if (text == "") {
    eMsgZone.classList.add("hidden");
    eMsgZone.classList.remove("warn-zone");
  } else {
    eMsgZone.classList.remove("hidden");
    eMsgZone.classList.add("warn-zone");
    
    setTimeout(() => {
      setMsgZone("");
    }, conf.msgTimeout);
  }
};

export async function showAlert(hash) {
  const state = getState();
  // Getting alert data from local storage
  let AlHistory = getAlHistory();

  // showing the list of alerts
  showAlertHistory(hash);
  setMsgZone("");

  let init = {
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (state?.user_data?.sid) {
    init.headers.Authorization = `Bearer ${state.user_data.sid}`;
  }
  let res = {};
  try {
    const url = conf.API_BASE_URL + `/v1/alerts/${hash}`;
    // console.log(url);
    res = await requestJSON(url, init);
  } catch(err){
    console.log(err);
    setMsgZone("Error de comunicación. No fue posible obtener los datos de la alerta.");
    return;
  }
  
  if (res.error) {
    console.log(res.error.status);
    setMsgZone("Alerta no encontrada en el servidor.");
    document.querySelector("#alert").classList.add("hidden");
    return {"errMsg": "Alerta no encontrada en el servidor."};
  }

  document.querySelector("#alert").classList.remove("hidden");

  // Checking if it's a recent alert
  const curTime = new Date();
  const alTime = new Date(res.received);
  if (curTime - alTime < 5*60*1000) {
    setMsgZone("⚠️ Alerta Reciente (<5min) ⚠️");
  }
  eReport.querySelector("#gname").textContent = res.gname;
  eDate.textContent = convDate(res.received);
  eType.textContent = conf.alKnd.filter(e => e.id == res.kind)[0].txt;
  eUname.textContent = res.lname.length>0 ? res.lname : res.sname;
  eGeoref.src = res.geoplan.length>0 ? res.geoplan : "./media/no-info.png";

  if (res.subcode) {
    let code = res.subcode.substr(0,4) + " " + res.subcode.substr(4,8);
    eNoteSubs.insertAdjacentHTML('afterbegin',`<a href="/?p=subs&code=${res.subcode}">${code}</a=>`);
  } else {
    eNoteSubs.textContent = "---- ----";
  }

  if (AlHistory.alerts.filter(e => {return e.hash == hash}).length == 0) {
    // this is a new hash
    AlHistory.alerts.unshift({
      date: res.received,
      kind: res.kind ? res.kind : 1,
      hash: hash,
      gname: res.gname,
      uname: res.lname.length>0 ? res.lname : res.sname
    })
    storeHistory(AlHistory);
  }
}

function getAlHistory() {
  let AlHistory = JSON.parse(localStorage.getItem("AlHistory"))
  if (AlHistory == null) {
    // There is no data stored ATM. Creating the base object
    AlHistory = {
      alerts: []
    }
  }
  return AlHistory;
}

// showAlertHistory will show the list of previous alerts in the page
export function showAlertHistory(hash) {
  let AlHistory = getAlHistory();

  if (AlHistory.alerts.length == 0) {
    document.querySelector("#oldAlerts p").textContent = "No se encontraron alertas anteriores.";
  } else {
    document.querySelector("#oldAlerts p").textContent = "";
    let al = document.querySelector("#oldAlerts #alerts");
    al.innerHTML = "";
    // recent alerts will also receive a distinctive look
    const curTime = new Date();
    let recentMark = "";
    const baseKind = conf.alKnd.filter(elem => elem.id == 1)[0].txt
    AlHistory.alerts.forEach(e => {
      let alTime = new Date(e.date);
      if (curTime - alTime < 5*60*1000) {
        recentMark = " ⚠️ ";
      } else {
        recentMark = "";
      }

      let kind = baseKind;
      if (e.kind) {
        kind = conf.alKnd.filter(elem => elem.id == e.kind)[0].txt;
      }

      let entry;
      let atxt = `${recentMark}${convDate(e.date)} - ${kind}${recentMark}`;
      
      let p = document.createElement("p");
      if (hash == e.hash) {
        // the one currently selected (then no need for an anchor)
        entry = document.createElement("strong");
        entry.textContent = `→ ${atxt} ←` ;
        p.appendChild(entry);
        p.appendChild(document.createElement("br"));
      } else {
        entry = document.createElement("a");
        entry.text = `${atxt}`;
        entry.href = `/?p=showAlert&h=${e.hash}`;
        p.appendChild(entry);
      }
      let info1 = document.createElement("span");
      info1.textContent = `${e.gname}`;
      
      let info2 = document.createElement("span");
      info2.textContent = `${e.uname}`;

      p.appendChild(info1);
      p.appendChild(document.createElement("br"));
      p.appendChild(info2);
      al.appendChild(p);
    });
  }
}

// storeHistory sorts the given alert history list, cut the extra elements,
// remove duplicates and store the result in the local history
function storeHistory(alHistory) {
  // sort entries
  alHistory.alerts.sort((a,b) => {
    // doing reverse order based on date string
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  })
  
  // console.log("sorted", alHistory);

  // cleaning (removing duplicates)
  let lastHash = "";
  let filteredAlerts = [];
  for (let i=0; i < alHistory.alerts.length; i++) {
    let al = alHistory.alerts[i];
    if (lastHash == "") {
      lastHash = al.hash;
      filteredAlerts.push(al);
      continue;
    }
    if (lastHash == al.hash) continue; // repeated
    lastHash = al.hash;
    filteredAlerts.push(al);
  }
  alHistory.alerts = filteredAlerts;
  // console.log("cleaned", alHistory);

  // ensuring max size
  // I want to keep up to 10 alerts only
  if (alHistory.alerts.length > conf.maxAlertHist) {
    alHistory.alerts = alHistory.alerts.slice(0,conf.maxAlertHist);
  }

  // finally storing the data
  localStorage.setItem("AlHistory", JSON.stringify(alHistory));
}

// updateHistory will get the list of alerts from the API and store them
// in the local storage. Will restore the list.
export async function updateHistory() {
  let alHistory = getAlHistory();
  const state = getState();
  state.exp = Date.now() + conf.sesExp;
  setState(state);
  
  let init = {
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${state.user_data.sid}`
    }
  };
  let res = {};
  try {
    const url = conf.API_BASE_URL + `/v1/alerts/list`;
    // console.log(url);
    res = await requestJSON(url, init);
  } catch(err){
    console.log(err);
    setMsgZone("Error de comunicación. No fue posible obtener datos de alertas.");
    return 400; // error
  }
  
  if (res.error) {
    if (res.error.status.slice(0,3) == '401') {
      return 401; // unauthorized
    } else {
      console.log(res.error.status);
      // setMsgZone("No se encontraron alertas en el servidor.");
      // document.querySelector("#alert").classList.add("hidden");
      return 404;
    }
  }

  for (let i=0; i < res.alerts.length; i++) {
    let al = res.alerts[i];
    alHistory.alerts.push({
      date: al.received,
      hash: al.hash,
      kind: al.kind,
      gname: al.gname,
      uname: al.lname.length>0 ? al.lname : al.sname
    })
  }
  // console.log(alHistory);

  storeHistory(alHistory);
  return 200;
}
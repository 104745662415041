'use strict';

import { requestJSON, urlBase64ToUint8Array } from "./utils.js";
import * as conf from "./config.js";

let groupCode = document.querySelector("#group_code");
const eMsgZone = document.querySelector('#subs-msg-zone');

// these are to set and get global state
let setState = () => { console.error("not defined yet")};
let getState = () => { console.error("not defined yet")};
export function setgetState(setST, getST) {
  setState = setST;
  getState = getST;
}

const setMsgZone = text => {
  eMsgZone.textContent = text;

  if (text == "") {
    eMsgZone.classList.add("hidden");
    eMsgZone.classList.remove("warn-zone");
  } else {
    eMsgZone.classList.remove("hidden");
    eMsgZone.classList.add("warn-zone");
  }
};

export function noSubsSupport() {
    setMsgZone("Este navegador no soporta notificaciones");
    document.querySelector('#subscriptions').classList.add('lgrey-txt');
}
// Esto puedo usar para avisar cuando una comunidad no tiene activo
// el servicio de notificaciones
// alert('La comunidad no tiene contratado el servicio de notificaciones')

document.querySelector('#subs_btn').addEventListener('click', async function() {
  subscribe(groupCode.value);
})

// subsCode is the subscription code, as string. Can contain spaces.
export async function subscribe(subsCode) {
  // == validate and normalize group code
  if (subsCode.length < 9) {
    alert('Debe ingresar el código completo');
    return;
  } else if (subsCode.length > 9) {
    alert('El código ingresado es incorrecto');
    return
  }
  const code = subsCode.replace(" ", "")

  // == Ensure we have notification persmission
  if (Notification.permission != "granted") {
    askForNotificationPermission();
    if (Notification.permission != "granted") {
      alert("Debe permitir las notificaciones para suscribirse");
      return;
    }
  }

  // == Getting the subscription data
  const swreg = await navigator.serviceWorker.ready
  if (!('pushManager' in swreg)) {
    document.querySelector('#subscriptions').classList.add('lgrey-txt');
    return
  }
  let subs = await swreg.pushManager.getSubscription();
  
  if (subs === null) {
    // Create a new subscription
    var vapidPublicKey = conf.VAPID_PUB_KEY;
    var convertedVapidPublicKey = urlBase64ToUint8Array(vapidPublicKey);
    subs = await swreg.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: convertedVapidPublicKey
    });
  } 
  
  // Now registering the subscription in the API
  let reqbody = {
    subscription: subs,
    groupcode: Number(code)
  }

  let init = {
    method: 'PUT',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqbody),
  };
  let res = {};
  try {
    res = await requestJSON(conf.API_BASE_URL + "/v1/subscriptions", init);
  } catch (err) {
    console.log(err);
    alert('Error de comunicación. Reintente más tarde por favor.');
    return;
  }

  if (res.error) {
    const code = res.error.status.slice(0,3);
    switch (code) {
      case "404":
        alert("Código Incorrecto");       
        break;
      case "402":
          alert("El servicio de notificaciones no está activado para esta comunidad");       
          break;
      default:
        alert("No se pudo registrar la suscripción")
        break;
    }
  } else if (res.status) {
    const code = res.status.slice(0,3);
    switch (code) {
      case "200":
      case "201":
        alert("Suscripción ingresada.");       
        break;          
    }
  }
}

// Unsubscribe Button Click
document.querySelector('#unsub_btn').addEventListener('click', async function() {
  // == validate and normalize group code
  if (groupCode.value.length < 9) {
    alert('Debe ingresar el código completo');
    return;
  }
  const code = groupCode.value.replace(" ", "")

  // == Getting subscription's data
  const swreg = await navigator.serviceWorker.ready;
  const subs = await swreg.pushManager.getSubscription();
  
  if (!subs) {
    console.log("no subscription registered. We're done here");
    alert("No hay suscripciones activas.");
    return;
  }

  let reqbody = {
    subscription: subs,
    groupcode: Number(code)
  }
  let init = {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqbody),
  };
  try {
    const resp = await requestJSON(conf.API_BASE_URL + "/v1/subscriptions", init);
    if (resp.error) {
      const code = resp.error.status.slice(0,3);
      switch (code) {
        case "404":
          alert("Código Incorrecto");       
          break;       
        default:
          alert("No se pudo quitar la suscripción. Reintente más tarde por favor.")
          break;
      }
    } else if (resp.status) {
      const code = resp.status.slice(0,3);
      switch (code) {
        case "200":
          // do not unsubscribe here because the same subscription
          // could be active for another group code
          // const unsub = await subs.unsubscribe();
          alert("Suscripción eliminada.");
          break;
        default:
          alert("Hubo un problema. Reintente más tarde por favor.")
          break;
      }
    }
  }
  catch(err) {
    console.log(err);
    alert('Error de comunicación. Reintente más tarde por favor.');
    return;
  }
})

groupCode.addEventListener('input', () => {
  const s = groupCode.value;
  if (s.length > 4 && s[4] != " ") {
    const first = s.slice(0,4);
    const second = s.slice(4);
    groupCode.value = first + " " + second
  }
})

function askForNotificationPermission() {
  Notification.requestPermission(function(result) {
    if (result !== 'granted') {
      console.log('No notification permission granted!');
      return false;
    } else {
      return true;
      // configurePushSub();
      // displayConfirmNotification();
    }
  });
}

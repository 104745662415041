'use strict';

import * as conf from "./config.js";

let month_es = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timed out after ${s} seconds`));
    }, s * 1000);
  });
};

export const requestJSON = async function (url, init) {
  if (!init.method) init.method = 'GET';

  const res = await Promise.race([
    fetch(url, init),
    timeout(conf.FETCH_TIMEOUT_SEC),
  ]);
  //console.log('res:', res)

  const data = await res.json();

  if (!res.ok)
    throw new Error(
      `Unable to ${init.method} data. URL: ${url}: (${res.status}) ${res.statusText}`
    );

  return data;
};

export function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function convDate(inDate) {
  let alTime = new Date(inDate);
  let dt = `${alTime.getDate().toString().padStart(2, '0')}`;
  dt += `/${month_es[alTime.getMonth()].slice(0,3)}`;
  dt += ` ${alTime.getHours().toString().padStart(2, '0')}`;
  dt += `:${alTime.getMinutes().toString().padStart(2, '0')}`;
  return dt;
}